type Callback = (...args: any[]) => void

interface EventBus {
  [key: string]: Callback[]
}

const eventBus: EventBus = reactive({})

export function useEventBus() {
  const emit = (event: string, ...args: any[]) => {
    if (eventBus[event]) {
      eventBus[event].forEach((callback) => callback(...args))
    }
  }

  const on = (event: string, callback: Callback) => {
    if (!eventBus[event]) {
      eventBus[event] = []
    }
    eventBus[event].push(callback)
  }

  const off = (event: string, callback: Callback) => {
    if (eventBus[event]) {
      eventBus[event] = eventBus[event].filter((cb) => cb !== callback)
    }
  }

  return { emit, on, off }
}
