<template>
  <div :class="[{ 'hide-zoom': !map.url }]" class="relative" id="mapContainer">
    <Loader />
    <LMap
      style="height: 100%"
      class="bg-grey-dark"
      :class="{ 'rounded-xl': rounded }"
      :center="center"
      :crs="crs"
      :min-zoom="-20"
      :max-zoom="maxZoomLevel"
      :options="{
        zoomControl: false,
        attributionControl: false,
        scrollWheelZoom: false,
        smoothWheelZoom: true,
        smoothSensitivity: 1,
        dragging: true,
        tap: true,
      }"
      :zoomAnimation="false"
      :zoom="zoom"
      ref="mapRef"
      :useGlobalLeaflet="true"
    >
      <Loader v-show="isLoadingPlan" />
      <div v-if="!isLoadingPlan">
        <LImageOverlay v-if="map.url" :url="map.url" :bounds="map.bounds" />

        <template v-if="selectedFloor && shouldShowAreas">
          <LFeatureGroup ref="areasRef">
            <template v-for="area of displayableAreas">
              <LPolygon
                v-if="area.polygon"
                :key="'area-polygon-' + selectedFloor.id + area.id"
                :lat-lngs="area.polygon"
                :fillOpacity="0.1"
                @mouseover="(ev) => ev.target.openPopup()"
                @mouseout="(ev) => ev.target.closePopup()"
                :options="{ area_id: area.id }"
              >
                <LPopup
                  :content="`${$t('resources.areas.name', 1)} ${area.name}`"
                  :options="{ autoPan: false }"
                />
              </LPolygon>
            </template>
          </LFeatureGroup>
        </template>

        <!-- Current selected desk -->
        <LMarkerClusterGroup
          v-if="selectedBuilding && selectedFloor"
          :spiderfyOnMaxZoom="false"
          :maxClusterRadius="30"
          :iconCreateFunction="markerIconCreate"
          @ready="clusterReady"
          :key="selectedBuilding.id + '-' + selectedFloor.id"
        >
          <LMarker
            v-for="d of desksSelected"
            :lat-lng="d.position"
            :icon="pulseIcon"
            :key="
              selectedBuilding.id +
              '-' +
              selectedFloor.id +
              '-' +
              selectedDayKey +
              '-' +
              d.uuid
            "
            @mouseover="(ev) => ev.target.openPopup()"
            @mouseout="(ev) => ev.target.closePopup()"
            :options="{ busy: true, my_desk: true, desk_id: d.uuid }"
            @click="onDeskSelected(d)"
          >
            <LPopup
              :content="`${$t('resources.desks.name', 1)} ${
                d.name
              }<br />${d.schedule
                .map(
                  (s) =>
                    `${$t('resources.presences.fields.periods.' + s.period)}
                        -
                        ${s.person.full_name}`
                )
                .join('<br />')}`"
            />
          </LMarker>

          <!-- Draft booking -->
          <LMarker
            v-for="d of desksDraft"
            :key="
              selectedBuilding.id +
              '-' +
              selectedFloor.id +
              '-' +
              selectedDayKey +
              '-draft-' +
              d.uuid
            "
            :lat-lng="d.position"
            :icon="userIcon(d.schedule[0].person, d.draft)"
            @mouseover="(ev) => ev.target.openPopup()"
            @mouseleave="(ev) => ev.target.closePopup()"
            :options="{ busy: true, my_desk: true, desk_id: d.uuid }"
            @click="onDeskSelected(d)"
          />

          <!-- Desks booked by others -->
          <LMarker
            v-for="d of desksBusy"
            :lat-lng="d.position"
            class-name="pointer-events-none"
            :icon="userIcon(d.schedule[0].person)"
            :key="
              selectedBuilding.id +
              '-' +
              selectedFloor.id +
              '-' +
              selectedDayKey +
              '-desk-booked-' +
              d.uuid
            "
            @mouseover="(ev) => ev.target.openPopup()"
            @mouseout="(ev) => ev.target.closePopup()"
            :options="{ busy: true, desk_id: d.uuid }"
            @click="onDeskSelected(d)"
          >
            <LPopup
              :content="`${$t('resources.desks.name', 1)} ${
                d.name
              }<br />${d.schedule
                .map(
                  (s) =>
                    `${$t('resources.presences.fields.periods.' + s.period)}
                        -
                        ${s.person.full_name}`
                )
                .join('<br />')}`"
            />
          </LMarker>

          <!-- Desks unavailable -->
          <LCircleMarker
            v-for="d of desksUnavailableBooking"
            :key="
              selectedBuilding.id +
              '-' +
              selectedFloor.id +
              '-' +
              selectedDayKey +
              '-desk-unavailable-' +
              d.uuid
            "
            :lat-lng="d.position"
            color="#e74c3c"
            :weight="markerSizesDependentOnZoom.busyDesk.weight"
            :weightOpacity="1"
            fillColor="#e74c3c"
            :fillOpacity="1"
            :radius="markerSizesDependentOnZoom.busyDesk.radius / 1.5"
            @mouseover="(ev) => ev.target.openPopup()"
            @mouseout="(ev) => ev.target.closePopup()"
            :options="{ busy: true, desk_id: d.uuid }"
            @click="onDeskSelected(d)"
          >
            <LPopup
              :content="`${$t('resources.desks.name', 1)} ${
                d.name
              } <br />Booking disabled`"
            />
          </LCircleMarker>

          <!-- Free desks -->
          <LCircleMarker
            v-for="d of desksAvailableBooking"
            :key="
              selectedBuilding.id +
              '-' +
              selectedFloor.id +
              '-' +
              selectedDayKey +
              '-desk-free-' +
              d.uuid
            "
            :lat-lng="d.position"
            color="#CEF9A5"
            :weight="markerSizesDependentOnZoom.freeDesk.weight"
            :weightOpacity="0.6"
            fillColor="#8EDB63"
            :fillOpacity="1"
            :radius="markerSizesDependentOnZoom.freeDesk.radius"
            @click="onDeskSelected(d)"
            @mouseover="(ev) => ev.target.openPopup()"
            @mouseout="(ev) => ev.target.closePopup()"
            :options="{ free: true, desk_id: d.uuid }"
          >
            <LPopup
              :content="
                $t('resources.desks.name', 1) +
                ' ' +
                d.name +
                '<br />Free<br />' +
                (d.equipments.length > 0
                  ? '<br /><b>Equipments</b><br />' +
                    d.equipments.map((e) => e.name).join('<br/>')
                  : '')
              "
            />
          </LCircleMarker>
        </LMarkerClusterGroup>
      </div>

      <!-- OPTIONAL CONTROLE FOR LOCATION - DATE - PERIOD -->
      <LControl
        v-show="!hideSelectors || (hideSelectors && showAreaSelector)"
        class="min-w-56"
      >
        <div
          v-if="buildings.length > 0"
          class="space_selector absolute right-0 min-w-full"
        >
          <SingleLocationSelect
            :areaOnly="showAreaSelector"
            noLabel
            :preloadBuildings="buildings"
            v-model="selectedLocations"
            oneRow
            @update:modelValue="updateFloorPlan($event)"
            :showBuildingSelector="showBuildingSelector"
          />
          <div
            v-if="readOnlySelectors"
            class="bg-white w-56 text-lg p-4 rounded-lg mt-2"
          >
            <p v-if="selectedDayKey">{{ $d(selectedDayKey+'T00:00:00', 'shortDate') }}</p>
            <p v-if="selectedPeriod">
              {{ t(`resources.presences.fields.periods.${selectedPeriod}`) }}
            </p>
          </div>
          <template v-else>
            <div
              class="mt-4"
              @click="isCalendarOpen = true"
              v-if="!hideSelectors"
            >
              <input
                type="text"
                name="date"
                id="date"
                :value="$d(selectedDayKey+'T00:00:00', 'shortDate')"
                readonly
                class="block w-full bg-white text-comeen-gray cursor-pointer rounded-md border-0 py-4 px-2 shadow placeholder:text-gray-400 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
              />
            </div>
            <vs-popup
              v-model:active="isCalendarOpen"
              @update:active="isCalendarOpen = $event"
              :title="$t('resources.presences.fields.date')"
              v-if="!hideSelectors"
            >
              <CalendarPicker
                v-model="selectedDay"
                class="w-full mt-4"
                :min-date="minDate"
                :max-date="maxDate"
                :isMultiple="false"
                :allowRange="false"
                @update:modelValue="isCalendarOpen = false"
              />
            </vs-popup>

            <Listbox
              v-if="props.showPeriodSelector && !props.isMultipleDatesMode"
              as="div"
              name="areas"
              id="areas"
              v-model="selectedPeriod"
            >
              <ListboxButton
                class="mt-4 relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none sm:text-sm sm:leading-6"
              >
                <span
                  v-if="props.enabledPeriods.length === 0"
                  class="block truncate"
                >
                  {{ $t('resources.actions.select') }}
                </span>
                <span class="block truncate" v-else>{{
                  $t(`resources.presences.fields.periods.${selectedPeriod}`)
                }}</span>
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <vs-icon
                    :icon="faCaretDown"
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                    color="#74b3e0"
                  />
                </span>
              </ListboxButton>
              <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    v-for="period in props.enabledPeriods"
                    :key="period"
                    :value="period"
                    v-slot="{ active, selected }"
                  >
                    <li
                      :class="[
                        active
                          ? 'bg-comeen-primary-400 text-white'
                          : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      ]"
                    >
                      {{ $t(`resources.presences.fields.periods.${period}`) }}
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </Listbox>
          </template>
        </div>
      </LControl>

      <!-- OPTIONAL TOGGLES AT THE BOTTOM OF THE MAP -->
      <LControl
        position="bottomleft"
        v-show="showLegend"
        class="bg-grey-lighter p-3 rounded-lg drop-shadow-lg"
      >
        <div class="flex flex-row text-grey-darker font-medium">
          <div class="flex items-center md:justify-start mr-3">
            <vs-switch v-model="shouldShowAreas" class="mr-2" />
            <span>{{ $t('resources.areas.name', 2) }}</span>
          </div>

          <div class="flex items-center md:justify-start mr-3">
            <vs-switch v-model="shouldShowAvailableDesks" class="mr-2" />
            <span>{{ t('free_desks') }}</span>
          </div>

          <div class="flex items-center md:justify-start mr-3">
            <vs-switch v-model="shouldShowBookedDesks" class="mr-2" />
            <span class="drop-shadow-xl">{{ t('booked_desks') }}</span>
          </div>

          <div
            class="flex items-center md:justify-start mr-3"
            v-if="isDisabledDesksToggleVisible"
          >
            <vs-switch
              v-model="shouldShowUnavailableToBookingDesks"
              class="mr-2"
            />
            <span>{{ t('unavailable_booking') }}</span>
          </div>
        </div>
      </LControl>

      <LControlZoom position="topleft"></LControlZoom>
    </LMap>

    <div
      v-if="!map.url && !isLoadingPlan"
      class="absolute h-full w-full text-center p-12 top-0 left-0"
    >
      <div class="relative h-full" style="top: 10%">
        <img
          src="@/assets/images/pages/my/nomap.svg"
          class="mx-auto mb-2"
          style="height: 70%"
          alt="empty map"
        />
        <span v-if="buildings.length > 0">{{ t('no_map') }}</span>
      </div>
    </div>
  </div>
</template>

<i18n locale="en" src="@/lang/en/components/map.json"></i18n>
<i18n locale="fr" src="@/lang/fr/components/map.json"></i18n>

<script setup>
import { getDefaultHeaderConfig } from '@/utils/api/utils.ts'
import { getUserSchedule } from '@/utils/api/scheduleActions.ts'
import { useEventBus } from '@/composables/eventBus'
import CalendarPicker from '@/components/comeen/utils/forms/CalendarPicker.vue'
import SingleLocationSelect from '@/components/comeen/utils/forms/SingleLocationSelect.vue'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import L from 'leaflet'

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'

import 'leaflet/dist/leaflet.css'
import {
  LCircleMarker,
  LControl,
  LControlZoom,
  LFeatureGroup,
  LImageOverlay,
  LMap,
  LMarker,
  LPolygon,
  LPopup,
} from '@vue-leaflet/vue-leaflet'
import 'vue-leaflet-markercluster/dist/style.css'

import Loader from '../../utils/loading/Loader.vue'

import { CRS } from 'leaflet'
import { LMarkerClusterGroup } from 'vue-leaflet-markercluster'

import {
  dateRangeToPeriod,
  dateStringForDate,
  dateTimesForPeriodAndDay,
} from '@/utils/date'
import { min } from 'lodash-es'
import { addMinutes } from 'date-fns'

let crs = null
let $L = null

crs = CRS.Simple

onBeforeMount(async () => {
  globalThis.L = L
  $L = L
})

const { t } = useI18n({ useScope: 'local' })
const { $auth } = useNuxtApp()
const userId = $auth.user.value.id

const emit = defineEmits([
  'desk-selected',
  'day-selected',
  'building-selected',
  'floor-selected',
  'area-selected',
  'loaded',
  'select-teammate',
])

// PROPS
const props = defineProps({
  assignSelectedDesk: {
    type: Boolean,
    default: false,
  },
  date: {
    type: [Date, Array],
    default: () => new Date(),
  },
  draftTeammate: {
    type: Object,
    default: null,
  },
  enabledPeriods: {
    type: Array,
    default: () => ['all_day', 'morning', 'afternoon'],
  },
  floor: {
    type: [Object, null],
    default: null,
  },
  forceBuilding: {
    type: Object,
    default: null,
  },
  hideSelectors: {
    type: Boolean,
    default: false,
  },
  isMultipleDatesMode: {
    Type: Boolean,
    default: false,
  },
  isSwitchingDesk: {
    type: Boolean,
    default: false,
  },
  period: {
    type: String,
    default: 'all_day',
  },
  reactiveAreaId: {
    type: [Number, null],
    default: null,
  },
  readOnlySelectors: {
    type: Boolean,
    default: false,
  },
  resize: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  showBuildingSelector: {
    type: Boolean,
    default: true,
  },
  showAreaSelector: {
    type: Boolean,
    default: false,
  },
  showDateSelector: {
    type: Boolean,
    default: false,
  },
  showFreeDesks: {
    type: Boolean,
    default: true,
  },
  showLegend: {
    type: Boolean,
    default: true,
  },
  showPeriodSelector: {
    type: Boolean,
    default: false,
  },
  temporaryDesk: {
    type: Object,
    default: null,
  },
  todayWorkActivitiesSchedule: {
    type: Array,
    default: [],
  },
  visibleToggles: {
    type: Array,
    default: () => ['areas', 'free_desks', 'booked_desks'],
    // must be one of those ['areas', 'free_desks', 'booked_desks', 'disabled_desks']
  },
})

// DATA
const autoSelectFloor = ref(true)
const buildings = ref([])
const zoom = ref(1)
const currentZoom = ref(null)
const deskToZoom = ref(null)
const desksSchedule = ref([])
const draftTeammates = ref([])
const floor_size = ref({
  width: 0,
  height: 0,
})
const isLoadingPlan = ref(false)
const isCalendarOpen = ref(false)
const map = reactive({
  url: null,
  bounds: null,
})
let markerSizesDependentOnZoom = {
  myDesk: {
    weight: 5,
    radius: 25,
  },
  busyDesk: {
    weight: 2,
    radius: 25,
  },
  freeDesk: {
    weight: 3,
    radius: 20,
  },
}
const maxZoomLevel = ref(3)
const minZoomLevel = ref(-20)
const selectedArea = ref(null)
const selectedBuilding = ref(null)
const selectedDay = ref(new Date())
const selectedFloor = ref(null)
const selectedPeriod = ref(props.period || props.enabledPeriods[0])
const shouldShowAreas = ref(true)
const shouldShowAvailableDesks = ref(true)
const shouldShowBookedDesks = ref(true)
const shouldShowUnavailableToBookingDesks = ref(false)
const teammates = ref([])
const temporarySelectedDesk = ref(null)
const zoomLevel = ref(1)

const { on, off } = useEventBus()

// COMPONENTS REFS
const areasRef = ref(null)
const center = ref([0, 0])
const clusterRef = ref(null)
const mapRef = ref(null)
const selectedLocations = ref({
  building_id: null,
  floor_id: null,
  area_id: null,
})

// COMPUTED
const minDate = computed(() => {
  let minDate = new Date()

  minDate.setUTCHours(0, 0, 0, 0)

  return minDate
})

const maxDate = computed(() => {
  const weeksInAdvance = $auth.space.weeks_in_advance || 1
  const advanceTime = weeksInAdvance * 7 * 86400 * 1000

  let maxDate = new Date(Date.now() + advanceTime)

  maxDate.setUTCHours(0, 0, 0, 0)

  return maxDate
})

const isAreaToggleVisible = computed(() => {
  return props.visibleToggles.includes('areas')
})
const isFreeDesksToggleVisible = computed(() => {
  return props.visibleToggles.includes('free_desks')
})
const isBookedDesksToggleVisible = computed(() => {
  return props.visibleToggles.includes('booked_desks')
})
const isDisabledDesksToggleVisible = computed(() => {
  return props.visibleToggles.includes('disabled_desks')
})

const displayableAreas = computed(() => {
  return selectedFloor.value.areas
    .filter((a) => a.x != null)
    .map((a) => ({
      ...a,
      polygon: [
        $L.latLng(
          floor_size.value.height - (a.y / 10000) * floor_size.value.height,
          (a.x / 10000) * floor_size.value.width
        ),
        $L.latLng(
          floor_size.value.height - (a.y / 10000) * floor_size.value.height,
          ((a.x + a.width) / 10000) * floor_size.value.width
        ),
        $L.latLng(
          floor_size.value.height -
            ((a.y + a.height) / 10000) * floor_size.value.height,
          ((a.x + a.width) / 10000) * floor_size.value.width
        ),
        $L.latLng(
          floor_size.value.height -
            ((a.y + a.height) / 10000) * floor_size.value.height,
          (a.x / 10000) * floor_size.value.width
        ),
      ],
    }))
})

const desks = computed(() => {
  let filteredDesks = desksSchedule.value

  if (props.draftTeammate?.person.id) {
    // handles teammates selection with draft status
    const desksMap = new Map()
    filteredDesks.forEach((desk) => {
      desksMap.set(desk.uuid, desk)
    })

    desksMap.forEach((desk) => {
      if (
        desk.uuid === props.draftTeammate.deskId &&
        !desk.schedule?.find(
          (el) => el.person.id !== props.draftTeammate.person.id
        )
      ) {
        desk.draft_person_id = props.draftTeammate.person.id
        desk.draft = true
        desk.schedule = [{ person: props.draftTeammate.person }]
      } else if (
        desk.uuid !== props.draftTeammate.deskId &&
        desk.schedule.find(
          (el) => el.person.id === props.draftTeammate.person.id
        )
      ) {
        desk.draft_person_id = null
        desk.draft = false
        desk.schedule = []
      }
    })

    const desksWithDraft = Array.from(desksMap, ([key, value]) => value)
    filteredDesks = desksWithDraft
    draftTeammates.value = desksWithDraft.filter((desk) => desk.draft)

    emit(
      'select-teammate',
      desksWithDraft.filter((desk) => desk.draft)
    )
  }
  filteredDesks = filteredDesks
    .filter(
      (d) =>
        d.x != null ||
        (d.x == null &&
          d.schedule.length > 0 &&
          d.schedule[0].person.id == userId)
    )
    .filter(
      (d) =>
        d.person_id == null ||
        d.person_id == userId ||
        d.draft ||
        (d.person_id != null && d.schedule.length > 0)
    )
    .filter((d) => props.showFreeDesks || d.schedule.length > 0)
    .filter(
      (d) =>
        shouldShowAvailableDesks.value ||
        !d.available_to_booking ||
        d.schedule.length > 0
    )
    .filter(
      (d) => shouldShowUnavailableToBookingDesks.value || d.available_to_booking
    )
    .filter(
      (d) =>
        shouldShowBookedDesks.value ||
        !d.available_to_booking ||
        d.schedule.length == 0
    )
    .filter(
      (d) =>
        selectedFloor.value.areas.some((area) => area.id === d.area_id) ||
        (!selectedFloor.value.areas.some((area) => area.id === d.area_id) &&
          d.schedule.length > 0) ||
        d.area_id === null
    )
    .map((d) => ({
      ...d,
      busy: d.schedule.length > 0,
      free: d.schedule.length === 0,
      position: $L.latLng(
        floor_size.value.height - (d.y / 10000) * floor_size.value.height,
        (d.x / 10000) * floor_size.value.width
      ),
      schedule: d.schedule.map((s) => {
        return {
          ...s,
          period: dateRangeToPeriod(s.start_datetime, s.end_datetime),
        }
      }),
    }))

  return filteredDesks
})

const desksSelected = computed(() => {
  return desks.value.filter((d) => {
    return (
      (temporarySelectedDesk &&
        temporarySelectedDesk.uuid === d.uuid &&
        !d.draft &&
        !props.temporaryDesk) ||
      (props.temporaryDesk && props.temporaryDesk.uuid === d.uuid && !d.draft)
    )
  })
})

const desksDraft = computed(() => {
  return desks.value.filter((d) => d.draft)
})

const desksBusy = computed(() => {
  return desks.value.filter((d) => d.busy && d.available_to_booking && !d.draft)
})

const desksUnavailableBooking = computed(() => {
  return desks.value.filter((d) => !d.available_to_booking)
})

const desksAvailableBooking = computed(() => {
  return desks.value.filter(
    (d) =>
      !(
        (temporarySelectedDesk &&
          temporarySelectedDesk.uuid === d.uuid &&
          !d.draft &&
          !props.temporaryDesk) ||
        (props.temporaryDesk &&
          props.temporaryDesk.uuid === d.uuid &&
          !d.draft) ||
        d.draft ||
        (d.busy && d.available_to_booking && !d.draft) ||
        !d.available_to_booking
      )
  )
})

function deskToMarker(desk) {
  return {
    lat: desk.position.lat,
    lng: desk.position.lng,
    options: {
      color: '#CEF9A5',
      weight: markerSizesDependentOnZoom.freeDesk.weight,
      weightOpacity: 0.6,
    },
  }
}

const pulseIcon = computed(() => {
  // if (!import.meta.client) return null
  const size =
    (markerSizesDependentOnZoom.myDesk.radius -
      markerSizesDependentOnZoom.myDesk.weight) *
    2
  return $L.divIcon({
    // Specify a class name we can refer to in CSS.
    className: 'css-icon',
    html: `
          <div style="border-radius: 100%; border: ${
            markerSizesDependentOnZoom.myDesk.weight
          }px solid #8EDB63; height: ${size}px; width: ${size}px; background: rgba(206, 249, 165, 0.4)"></div>
          <div class="pulse_ring relative" style="top: ${
            -48 - 2 + markerSizesDependentOnZoom.myDesk.weight
          }px; left: -${
      markerSizesDependentOnZoom.myDesk.weight - 1
    }px;"></div>`,
    iconSize: [
      markerSizesDependentOnZoom.myDesk.radius * 2,
      markerSizesDependentOnZoom.myDesk.radius * 2,
    ],
  })
})

const todayWorkActivities = computed(async () => {
  let todayActivities = null
  await getUserSchedule('me', new Date(), new Date()).then((response) => {
    todayActivities = response.schedule[format(new Date(), 'yyyy-MM-dd')]
  })
  return todayActivities
})

const todayPresences = computed(() => {
  let todaySchedule = null
  getUserSchedule('me', new Date(), new Date()).then((response) => {
    todaySchedule = response.schedule[format(new Date(), 'yyyy-MM-dd')]
  })
  return todaySchedule.filter((activity) => activity.uuid)
})

const todayWorkActivity = computed(() => {
  return todayWorkActivities.find((workActivity) => {
    let now = new Date()
    let start_datetime = new Date(workActivity.start_datetime)
    let end_datetime = new Date(workActivity.end_datetime)

    return now >= start_datetime && now <= end_datetime
  })
})
const todayPresence = computed(() => {
  if (todayWorkActivity?.uuid) {
    return todayWorkActivity
  } else {
    return null
  }
})
const currentDesk = computed(() => {
  if (todayPresence?.desk) {
    return {
      ...todayPresence.desk,
      floor: todayPresence.floor,
      position: $L.latLng(
        floor_size.value.height -
          (todayPresence.desk.y / 10000) * floor_size.value.height,
        (todayPresence.desk.x / 10000) * floor_size.valiue.width
      ),
    }
  } else {
    return null
  }
})
const selectedDayKey = computed({
  get() {
    if (Array.isArray(props.date) && props.date.length > 1) {
      const selectedDays = props.date.slice(-1)
      selectedDays.unshift(props.date[0])
      return selectedDays
    }

    return dateStringForDate(
      Array.isArray(toValue(selectedDay))
        ? toValue(selectedDay)[0]
        : toValue(selectedDay)
    )
  },
  set(newDateString) {
    selectedDay.value = new Date(newDateString)
    fetchFloorSchedule()
  },
})

const zoomLevelRange = computed(() => {
  const zoom = zoomLevel.value

  const scale = maxZoomLevel.value - minZoomLevel.value

  const far = minZoomLevel.value + 1 * (scale / 4)
  const medium = minZoomLevel.value + 2 * (scale / 4)
  const close = minZoomLevel.value + 3 * (scale / 4)

  return zoom > close ? 3 : zoom > medium ? 2 : zoom > far ? 1 : 0
})

const markerIconCreate = function (cluster) {
  let busy_count = 0,
    free_count = 0,
    has_my_desk = false
  cluster.getAllChildMarkers().forEach((m) => {
    has_my_desk ||= m.options.my_desk

    if (m.options.free || m.options.my_desk) {
      free_count++
    } else if (m.options.busy) {
      busy_count++
    }
  })

  let cluster_class = 'free'
  if (free_count > 0) {
    cluster_class = 'free'
  } else {
    cluster_class = 'busy'
  }

  return $L.divIcon({
    className:
      free_count + busy_count > 0 ? 'cluster ' + cluster_class : 'hidden',
    html: `<div>${free_count}/${free_count + busy_count} ${
      has_my_desk
        ? '<div class="pulse_ring relative" style="top: -45px; left: -15px; z-index: -2;"></div>'
        : ''
    }</div>`,
  })
}

// WATCHERS
watch(
  () => props.date,
  (value) => {
    selectedDay.value = value
    nextTick(async () => {
      await fetchFloorSchedule()
    })
  }
)

watch(
  () => draftTeammates.value,
  (newValue, oldValue) => {
    draftTeammates.value = newValue
    emit(
      'desk-selected',
      temporarySelectedDesk.value,
      props.date,
      draftTeammates.value
    )
  },
  { deep: true }
)

watch(
  () => props.floor,
  (value) => {
    if (value) {
      autoSelectFloor.value = false
      selectedFloor.value = value

      let foundFloor = null
      let buildingIndex = 0

      while (!foundFloor && buildingIndex < buildings.value.length) {
        foundFloor = buildings.value[buildingIndex].floors.find(
          (f) => f.id === props.floor.id
        )
        buildingIndex++
      }

      selectedBuilding.value = buildings.value[buildingIndex - 1]
    }
  }
)

watch(
  () => props.resize,
  (value) => {
    if (value) {
      onResize()
    }
  }
)

watch(
  () => selectedDay.value,
  (day) => {
    fetchFloorSchedule()
    emit('day-selected', day)
  }
)

watch(
  () => selectedPeriod.value,
  (period) => {
    fetchFloorSchedule()
  }
)

watch(
  () => selectedArea.value,
  (newArea, previousArea) => {
    if (newArea) {
      if (areasRef.value) {
        areasRef.value.leafletObject.eachLayer((layer) => {
          if (layer.options.area_id == newArea.id) {
            layer.openPopup()
            mapRef.value.leafletObject.fitBounds(layer.getBounds())
          }
        })
      }
      nextTick(() => {
        selectedArea.value = null
      })
    }
    emit('area-selected', selectedArea.value)
  }
)

watch(
  () => props.enabledPeriods,
  (newValue) => {
    selectedPeriod.value =
      newValue.find((p) => p == props.period) || newValue[0]
  }
)

watch(
  () => props.reactiveAreaId,
  (id) => {
    if (id && id !== selectedArea.value?.id) {
      selectedArea.value = selectedFloor.value?.areas?.find(
        (area) => area.id === id
      )
    }
  }
)

watch(
  () => selectedBuilding.value,
  (building) => {
    emit('building-selected', building)
    if (autoSelectFloor.value && building) {
      selectedFloor.value = building.floors[0]
    }
    autoSelectFloor.value = true
  }
)

watch(
  () => selectedFloor.value,
  (floor) => {
    emit('floor-selected', floor)
    currentZoom.value = null
    if (selectedFloor.value) {
      if (selectedFloor.value.floorplan_url) {
        isLoadingPlan.value = true
        nextTick(() => {
          let img = new Image()
          img.onerror = () => {
            isLoadingPlan.value = false
          }
          img.onload = async () => {
            floor_size.value.height = img.height
            floor_size.value.width = img.width
            const bounds = [
              [0, 0],
              [img.height, img.width],
            ]
            map.url = selectedFloor.value.floorplan_url
            map.bounds = bounds
            img = null
            minZoomLevel.value = -20
            mapRef.value.leafletObject.setMinZoom(-20)
            nextTick(async () => {
              minZoomLevel.value =
                mapRef.value.leafletObject.getBoundsZoom(bounds)
              mapRef.value.leafletObject.setZoom(toValue(minZoomLevel))
              setTimeout(() => {
                mapRef.value.leafletObject.setMinZoom(toValue(minZoomLevel) - 1)
              }, 500)
              nextTick(async () => {
                mapRef.value.leafletObject.on('zoomend wheel', () => {
                  zoomLevel.value = mapRef.value.leafletObject.getZoom()
                })
                mapRef.value.leafletObject.once('zoomend moveend', () => {
                  if (deskToZoom.value) {
                    setTimeout(() => {
                      if (deskToZoom.value) {
                        currentZoom.value = deskToZoom.value
                        zoomDeskLayer(
                          mapRef.value.leafletObject,
                          deskToZoom.value
                        )
                      }
                      deskToZoom.value = null
                    }, 400)
                  }
                })
                nextTick(() => {
                  mapRef.value.leafletObject.fitBounds(bounds)
                })
                isLoadingPlan.value = false
                await fetchFloorSchedule()
              })
            })
          }
          img.src = selectedFloor.value.floorplan_url
        })
      } else {
        map.url = null
        desksSchedule.value = []
      }
    }
  }
)

watch(
  () => zoomLevel.value,
  () => {
    const range = maxZoomLevel.value - -2 + 2

    const percentage = (zoomLevel.value + 2) / range

    const font = 0.5 + 2 * percentage
    const padding = 2 + 10 * percentage
  }
)

watch(
  () => props.showAreaSelector,
  (value) => {
    shouldShowAreas.value = value
    if (value && props.floor) {
      selectedLocations.value.building_id = props.floor.building.id
      selectedLocations.value.floor_id = props.floor.id
      selectedLocations.value.area_id = null
    }
  }
)

watch(
  selectedBuilding,
  (value) => (selectedLocations.value.building_id = value?.id)
)
watch(selectedFloor, (value) => (selectedLocations.value.floor_id = value?.id))
watch(selectedArea, (value) => (selectedLocations.value.area_id = value?.id))

watch(
  () => props.period,
  (value) => {
    selectedPeriod.value = value
  }
)

// METHODS
const clusterReady = (cluster) => {
  clusterRef.value = cluster
}

const centerMap = () => {
  const container = document.getElementById('mapContainer')
  if (container) {
    const rect = container.getBoundingClientRect()
    const containerCenter = [
      rect.top + rect.height / 2,
      rect.left + rect.width / 2,
    ]
    center.value = containerCenter
  }
}

const fetch = async () => {
  let start_date, end_date
  if (Array.isArray(props.date) && props.date.length == 1) {
    const dates = dateTimesForPeriodAndDay(props.date[0], selectedPeriod.value)
    start_date = new Date(dates[0]).toISOString().split('T')[0]
    end_date = new Date(dates[1]).toISOString().split('T')[0]
  } else if (Array.isArray(props.date)) {
    start_date = new Date(props.date[0]).toISOString().split('T')[0]
    end_date = new Date(props.date.slice(-1)[0]).toISOString().split('T')[0]
  } else {
    const dates = dateTimesForPeriodAndDay(
      selectedDay.value,
      selectedPeriod.value
    )
    start_date = new Date(dates[0]).toISOString().split('T')[0]
    end_date = new Date(dates[1]).toISOString().split('T')[0]
  }
  try {
    const response = await $fetch(
      `/api/v1/spaces/${$auth.space.uuid}/buildings.json`,
      {
        headers: getDefaultHeaderConfig(),
        params: {
          time: Date.now(),
          start_datetime: start_date,
          end_datetime: end_date,
        },
      }
    )

    buildings.value = response.filter((b) => b.floors.length > 0)

    nextTick(() => {
      if (props.forceBuilding) {
        autoSelectFloor.value = false

        selectedBuilding.value = buildings.value.find(
          (b) => b.id === props.forceBuilding.id
        )
        if (selectedBuilding.value) {
          selectedFloor.value = selectedBuilding.value.floors[0]
        }
      } else if (props.floor) {
        autoSelectFloor.value = false

        let foundFloor = null
        let buildingIndex = 0

        while (!foundFloor && buildingIndex < buildings.value.length) {
          foundFloor = buildings.value[buildingIndex].floors.find(
            (f) => f.id === props.floor.id
          )
          buildingIndex++
        }

        selectedBuilding.value = buildings.value[buildingIndex - 1]
        selectedFloor.value = foundFloor
      } else if (todayPresence && todayPresence.desk) {
        autoSelectFloor.value = false
        deskToZoom.value = todayPresence.desk
        selectedBuilding.value = todayPresence
          ? buildings.value.find((b) => b.id === todayPresence.building.id)
          : buildings.value[0]
        selectedBuilding.value = selectedBuilding.value || buildings.value[0]
        if (
          selectedBuilding.value &&
          selectedBuilding.value.floors.length > 0
        ) {
          selectedFloor.value = currentDesk.floor
            ? selectedBuilding.value.floors.find(
                (f) => f.id === currentDesk.floor.id
              )
            : selectedBuilding.value.floors[0]
        } else {
          selectedFloor.value = null
        }
      } else {
        autoSelectFloor.value = false
        selectedBuilding.value = todayPresence
          ? buildings.value.find((b) => b.id === todayPresence?.building?.id)
          : null
        selectedBuilding.value = selectedBuilding.value || buildings.value[0]
        if (
          selectedBuilding.value &&
          selectedBuilding.value.floors.length > 0
        ) {
          selectedFloor.value =
            todayPresence && todayPresence.floor
              ? selectedBuilding.value.floors.find(
                  (f) => f.id === todayPresence.floor.id
                )
              : selectedBuilding.value.floors[0]
        } else {
          selectedFloor.value = null
        }
      }

      emit('loaded')
    })
  } catch (error) {
    console.error(error)
  }
}

const updateFloorPlan = (locations) => {
  if (
    (locations.building_id && !selectedBuilding) ||
    locations.building_id !== selectedBuilding.id
  ) {
    selectedBuilding.value = buildings.value.find(
      (b) => b.id === locations.building_id
    )
  }

  if (
    (locations.floor_id && !selectedFloor.value) ||
    (locations.floor_id && locations.floor_id !== selectedFloor.value.id)
  ) {
    selectedFloor.value = selectedBuilding.value.floors.find(
      (f) => f.id === locations.floor_id
    )
    fetchFloorSchedule()
  }

  if (
    (locations.area_id && !selectedArea.value) ||
    (locations.area_id && locations.area_id !== selectedArea.value?.id)
  ) {
    selectedArea.value = selectedFloor.value.areas.find(
      (a) => a.id === locations.area_id
    )
  }
}
const zoomDesk = (desk) => {
  Promise.resolve(window.scrollTo({ top: 0, behavior: 'smooth' }))

  var newSelectedBuilding, newSelectedFloor
  if (desk.floor) {
    newSelectedBuilding = buildings.value.find(
      (b) => b.id === desk.floor.building_id
    )
    if (newSelectedBuilding) {
      newSelectedFloor = newSelectedBuilding.floors.find(
        (f) => f.id === desk.floor.id
      )
    }
  } else {
    newSelectedBuilding = buildings.value.find(
      (b) => b.floors.findIndex((f) => f.id == desk.floor_id) > -1
    )
    if (newSelectedBuilding) {
      newSelectedFloor = newSelectedBuilding.floors.find(
        (f) => f.id === desk.floor_id
      )
    }
  }

  if (
    selectedBuilding.value === newSelectedBuilding &&
    selectedFloor.value === newSelectedFloor &&
    mapRef.value
  ) {
    deskToZoom.value = desk
    if (desk.area_id) {
      selectedArea.value = selectedFloor.value?.areas?.find(
        (area) => area.id === desk.area_id
      )
    }
    zoomDeskLayer(mapRef.value.leafletObject, desk)
  } else {
    autoSelectFloor.value = false
    selectedBuilding.value = newSelectedBuilding
    nextTick(() => {
      selectedFloor.value = newSelectedFloor
      deskToZoom.value = desk
    })
  }

  currentZoom.value = desk
}
const zoomDeskLayer = (l, desk) => {
  l.eachLayer((layer) => {
    if (layer.eachLayer) {
      zoomDeskLayer(layer, desk)
    } else {
      if (layer.options && layer.options.desk_id === desk.uuid) {
        const map = mapRef.value.leafletObject
        const targetLatLng = L.latLng(
          floor_size.value.height - (desk.y / 10000) * floor_size.value.height,
          (desk.x / 10000) * floor_size.value.width
        )

        nextTick(() => {
          clusterRef.value.zoomToShowLayer(layer, () => {
            map.setView(targetLatLng, { animate: true })
          })

          if (layer.getPopup()) {
            layer.openPopup()
          }
        })
      }
    }
  })
}

const zoomPresence = (presence) => {
  const newSelectedBuilding = buildings.value.find(
    (b) => b.id === presence.building.id
  )
  if (newSelectedBuilding) {
    const newSelectedFloor = newSelectedBuilding.floors.find(
      (f) => f.id === presence.floor?.id
    )
    autoSelectFloor.value = false
    selectedBuilding.value = newSelectedBuilding
    if (newSelectedFloor) {
      selectedFloor.value = newSelectedFloor
    } else {
      selectedFloor.value = selectedBuilding.value.floors[0]
    }
  }
}

const fetchFloorSchedule = async () => {
  if (selectedFloor.value) {
    isLoadingPlan.value = true

    let start_date, end_date
    if (Array.isArray(props.date) && props.date.length == 1) {
      // const dates = dateTimesForPeriodAndDay(
      //   props.date[0],
      //   selectedPeriod.value
      // )
      start_date = dateFormatForAPI(dates[0], 'yyyy-MM-dd HH:mm:ss')
      end_date = dateFormatForAPI(dates[1], 'yyyy-MM-dd HH:mm:ss')
    } else if (Array.isArray(props.date)) {
      start_date = dateFormatForAPI(props.date[0])
      end_date = dateFormatForAPI(props.date.slice(-1)[0])
    } else {
      const dates = dateTimesForPeriodAndDay(
        selectedDay.value,
        selectedPeriod.value
      )
      start_date = dateFormatForAPI(dates[0], 'yyyy-MM-dd HH:mm:ss')
      end_date = dateFormatForAPI(dates[1], 'yyyy-MM-dd HH:mm:ss')
    }

    try {
      const response = await $fetch(
        `/api/v1/floors/${selectedFloor.value.id}/desks_schedule.json`,
        {
          headers: getDefaultHeaderConfig(),
          params: {
            time: Date.now(),
            start_date: start_date,
            end_date: end_date,
          },
        }
      )

      desksSchedule.value = response.map((schedule) => {
        return { ...schedule, draft: false, draft_person_id: null }
      })
    } catch (error) {
      console.error(error)
    } finally {
      isLoadingPlan.value = false
    }
  }
}

const onDeskSelected = (desk) => {
  if (Array.isArray(props.date) && props.date.length > 1) {
    temporarySelectedDesk.value = desk
    emit('desk-selected', desk, props.date)
    zoomToDesk(desk)
  } else {
    const dates = dateTimesForPeriodAndDay(
      selectedDay.value,
      selectedPeriod.value
    )
    if (props.assignSelectedDesk && !desk.busy && desk.available_to_booking) {
      temporarySelectedDesk.value = desk
    }

    emit('desk-selected', desk, dates, draftTeammates.value)
  }
}

const areaIcon = (area) => {
  return $L.divIcon({
    // Specify a class name we can refer to in CSS.
    className: 'area-icon',
    html: `
        <div class="w-fit font-bold ml-3 mt-3 bg-white rounded-lg whitespace-nowrap drop-shadow-md">${area.name}</div>
        `,
  })
}

const userIcon = (person, draft) => {
  const size =
    (markerSizesDependentOnZoom.busyDesk.radius -
      markerSizesDependentOnZoom.busyDesk.weight) *
    2

  return $L.divIcon({
    // Specify a class name we can refer to in CSS.
    className: 'css-icon',
    html: `
          <div style="border-radius: 100%; border: ${
            markerSizesDependentOnZoom.busyDesk.weight
          }px solid ${
      draft ? `transparent` : `rgba(231, 76, 60, 1)`
    }; height: ${markerSizesDependentOnZoom.busyDesk.radius * 2}px; width: ${
      markerSizesDependentOnZoom.busyDesk.radius * 2
    }px; background: ${draft ? `transparent` : `rgba(231, 76, 60, 1)`}"></div>
          <div class="relative" style="top: -${
            size + markerSizesDependentOnZoom.busyDesk.weight
          }px; left: ${
      markerSizesDependentOnZoom.busyDesk.weight
    }px; height: ${size}px; width: ${size}px;"><img class="w-full h-full rounded-full ${
      draft ? `border-2 border-dotted` : ``
    }" src="${person?.avatar ? person.avatar : '/images/user.svg'}" /></div>`,
    iconSize: [
      markerSizesDependentOnZoom.busyDesk.radius * 2,
      markerSizesDependentOnZoom.busyDesk.radius * 2,
    ],
  })
}

const onResize = () => {
  if (map.url) {
    nextTick(() => {
      setTimeout(function () {
        mapRef.value.leafletObject.invalidateSize()
        nextTick(() => {
          minZoomLevel.value = -20
          mapRef.value.leafletObject.setMinZoom(-20)
          nextTick(async () => {
            minZoomLevel.value = mapRef.value.leafletObject.getBoundsZoom(
              map.bounds
            )
            mapRef.value.leafletObject.setZoom(toValue(minZoomLevel))
            setTimeout(() => {
              mapRef.value.leafletObject.setMinZoom(toValue(minZoomLevel) - 1)
            }, 500)
            nextTick(() => {
              if (currentZoom.value) {
                mapRef.value.leafletObject.setView(
                  $L.latLng(
                    floor_size.value.height -
                      (currentZoom.value.y / 10000) * floor_size.value.height,
                    (currentZoom.value.x / 10000) * floor_size.value.width
                  ),
                  0
                )
              } else {
                mapRef.value.leafletObject.fitBounds(map.bounds)
              }
            })
          })
        })
      }, 400)
    })
  }
}

onMounted(() => {
  if (Array.isArray(props.date)) {
    selectedDay.value = props.date[0]
  } else if (props.date && !Array.isArray(props.date)) {
    selectedDay.value = props.date
  } else {
    selectedDay.value = new Date()
  }
  fetch()

  selectedLocations.value = {
    building_id: selectedBuilding.id,
    floor_id: selectedFloor.id,
    area_id: selectedArea.id,
  }

  nextTick().then(() => {
    centerMap()
  })

  on('zoomPresence', (presence) => {
    if (presence && !props.isSwitchingDesk) {
      if (presence.desk) {
        zoomDesk(presence.desk)
        deskToZoom.value = presence.desk
      } else {
        this.zoomPresence(presence)
      }
    } else {
      onResize()
    }
  })
  on('fitFloorPlan', () => {
    nextTick(() => {
      onResize()
    })
  })
})

onBeforeUnmount(() => {
  off('zoomPresence', (presence) => {
    deskToZoom.value = null
    zoom.value = 1
  })
})

defineOptions({
  name: 'Map',
})

defineExpose({
  mapRef,
  fetchFloorSchedule,
})
</script>

<style lang="scss">
.space_selector .con-select .vs-select--input {
  padding: 5px 8px;
}

.cluster {
  margin-left: -20px !important;
  margin-top: -20px !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 973;
  opacity: 1;
  outline: none;
  border-radius: 100%;
  color: #000;
}
.cluster.free {
  background-color: rgba(181, 226, 140, 0.6);
}
.cluster.busy {
  background-color: rgba(231, 76, 60, 0.6);
}

.cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 100%;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 30px;
}
.cluster.free div {
  background-color: rgba(110, 204, 57, 1);
}
.cluster.busy div {
  background-color: rgba(231, 76, 60, 1);
}
</style>

<style scoped>
:deep(.pulse_ring) {
  border: 6px solid #8edb63;
  border-radius: 100%;
  height: 48px;
  width: 48px;
  animation: pulsate 2s ease-out;
  -webkit-animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2, 2);
    opacity: 0;
  }
}

:global(.leaflet-container) {
  background-color: #cee0f0;
}

:deep(.teammate-marker) {
  z-index: 210 !important;
  border-radius: 50%;
  background-color: white;
  color: white;
}

:deep(.leaflet-popup-pane) {
  cursor: pointer;
}

.map-card.hide-zoom :deep(.leaflet-control-zoom) {
  display: none !important;
}
</style>
